import _ from "underscore";
import {
  sendBreadcrumbToSentry,
  breadcrumbData,
} from "../services/commonUsefulFunctions";
import * as Sentry from "@sentry/browser";

export default function broadcastGenerator() {
  return {
    subscriptions: {} as {[event: string]: (...args: any[]) => any},

    subscribe(event: string, callback: (...args: any[]) => any) {
      this.subscriptions[event] = callback;
    },

    unsubscribe(event: string) {
      if (this.subscriptions[event]) {
        this.subscriptions = _.omit(this.subscriptions, event);
      }
    },

    publish(event: string, ...args: any[]) {
      sendBreadcrumbToSentry({
        category: "Broadcast",
        message: event,
        // include args if none are array/object, to mitigate 413 error risk
        data: breadcrumbData(args),
      });
      if (this.subscriptions[event]) {
        return this.subscriptions[event](...args);
      } else {
        sendBreadcrumbToSentry({
          category: "Broadcast",
          message: `${event} doesn't exist`,
          level: Sentry.Severity.Warning,
        });
      }
    },

    isSubscribedToEvent(event: string) {
      return Boolean(this.subscriptions[event]);
    },
  };
}
