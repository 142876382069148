import React from "react";

const VimcalIcon = ({ height, width, className, fillColor="white" }) => (
  <div className={className}>
    <svg
      width={width ?? "271"}
      height={height ?? "257"}
      viewBox="0 0 271 257"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2378 50.9909C-0.541023 67.8046 -5.99538 91.3493 8.19747 109.432C18.1118 122.063 33.8683 127.409 48.6305 124.646C108.649 66.4325 166.325 47.5271 210.406 43.8142C217.222 43.2401 223.706 43.0297 229.816 43.092C241.897 30.3028 255.497 18.8891 270.386 9.15991L270.495 8.88704C270.495 8.88704 206.181 -6.09589 143.576 2.84694C88.9103 10.6556 34.9821 29.954 15.2378 50.9909Z"
        fill={fillColor ?? "white"}
      />
      <path
        d="M59.5972 174.913C47.789 159.869 47.9866 139.23 58.7993 124.546C115.737 71.4193 169.819 54.1945 210.988 50.7269L221.77 52.0774C201.631 75.81 186.424 103.634 177.437 133.835C165.585 137.062 153.883 142.971 142.762 150.724C128.128 160.925 114.309 174.442 102.185 189.665C86.8215 193.396 69.9962 188.162 59.5972 174.913Z"
        fill={fillColor ?? "white"}
      />
      <path
        d="M107.237 194.459C106.395 195.974 105.639 197.559 104.977 199.21C96.4145 220.543 106.766 244.779 128.099 253.342C135.158 256.176 142.533 256.939 149.556 255.917C156.583 254.931 163.453 252.134 169.437 247.437C170.684 246.458 171.86 245.422 172.965 244.336C171.973 239.586 171.126 234.775 170.43 229.904C166.091 199.526 168.022 169.699 175.243 141.733C165.686 144.864 156.1 149.881 146.729 156.414C132.567 166.287 119.089 179.496 107.237 194.459Z"
        fill={fillColor ?? "white"}
      />
      <path
        d="M210.988 50.7269C215.301 50.3636 219.475 50.151 223.499 50.0644C222.919 50.732 222.342 51.4031 221.77 52.0774L210.988 50.7269Z"
        fill={fillColor ?? "white"}
      />
    </svg>
  </div>
);

export default VimcalIcon;
