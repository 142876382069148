import { LOCAL_DATA_ACTION } from "../lib/localData";
import {
  convertTrueFalseStringIntoValue,
  localData,
} from "./commonUsefulFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export function getBlockedCalendars(userAccount: Optional<User>) {
  return userAccount?.availability_settings?.blocked_calendars;
}

export function setHasBookedOnboarding() {
  localData(LOCAL_DATA_ACTION.SET, "hasBookedOnboarding", true);
}

export function hasBookedOnboarding() {
  const hasBooked = localData(LOCAL_DATA_ACTION.GET, "hasBookedOnboarding");
  return convertTrueFalseStringIntoValue(hasBooked);
}

export function hasAccountBeenOnTeamPlan(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return false;
  }

  return masterAccount.has_been_on_team_plan;
}

export function getMasterAccountStripeEmail(masterAccount: OptionalOrEmptyObject<MasterAccount>) {
  return masterAccount?.stripe_email;
}
