import { type Styles as ModalStyles } from "react-modal";
import { shadeInputColor } from "../services/commonUsefulFunctions";
import {
  DARK_MODE_BACKGROUND_COLOR,
  DARK_MODE_MODAL_TEXT_COLOR,
  DARK_MODE_SECONDARY_TEXT_COLOR,
  DARK_MODE_TEXT_COLOR,
  DEFAULT_FONT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
} from "../services/globalVariables";
import { isTemporaryAIEvent } from "./availabilityFunctions";
import {
  doesEventSpanMultipleDays,
  shouldShowTransparentMergedBackground,
} from "./eventFunctions";
import { MODAL_OVERLAY_Z_INDEXES } from "./modalFunctions";
import { isFindTimeEventFormEvent } from "./temporaryEventFunctions";

export function getDefaultFontColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? DARK_MODE_TEXT_COLOR : DEFAULT_FONT_COLOR;
}

export function getDefaultSecondaryTextColor(isDarkMode: Optional<boolean>) {
  return isDarkMode
    ? DARK_MODE_SECONDARY_TEXT_COLOR
    : LIGHT_MODE_SECONDARY_TEXT_COLOR;
}

export function getSwitchOffColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "#67686F" : "#C8CDD4";
}

export function getTentativeBackgroundStyle(color: string) {
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 10px,
    ${shadeInputColor(color)} 10px,
    ${shadeInputColor(color)} 20px`;
}

export function getTentativeSideBarBackgroundStyle({
  color,
  isDarkMode,
}: { color: string, isDarkMode: Optional<boolean> }) {
  const defaultBackgroundColor = getDefaultBackgroundColor(isDarkMode);
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 3px,
    ${defaultBackgroundColor} 3px,
    ${defaultBackgroundColor} 6px`;
}

export function getDefaultBackgroundColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? DARK_MODE_BACKGROUND_COLOR : "white";
}

export function getDefaultMonthlyCalendarOutOfRangeColor(isDarkMode: Optional<boolean>) {
  // "#202027" is taken from inspector because the original color has transparency
  return isDarkMode ? "#202027" : "#F8F9FA";
}

const MODAL_BACKGROUND_TYPE = {
  WITH_OPACITY: "WITH_OPACITY",
  WITHOUT_OPACITY: "WITHOUT_OPACITY",
} as const;

function getDarkModeModalBackgroundColorWithNoOpacity(type: ValueOf<typeof MODAL_BACKGROUND_TYPE>) {
  switch (type) {
    case MODAL_BACKGROUND_TYPE.WITH_OPACITY:
      return "rgba(52, 54, 69, 0.5)";
    case MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY:
      return "rgba(52, 54, 69, 1)";
    default:
      return "rgba(52, 54, 69, 1)";
  }
}

export function getDefaultModalBorder(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "1px solid #3C3E4D" : "1px solid #E5E7EB"; // .default-bottom-left-modal-border
}

export function getCalendarGridColor(isDarkMode: Optional<boolean>) {
  return isDarkMode ? "#343645" : "#E5E7EB";
}

export function getModalBackgroundColorWithNoOpacity(isDarkMode: Optional<boolean>) {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY)
    : "rgb(255, 255, 255)";
}

export const getModalBackgroundColorWithExtraOpacity = (isDarkMode: Optional<boolean>) => {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(MODAL_BACKGROUND_TYPE.WITH_OPACITY)
    : "rgba(255, 255, 255, 0.4)";
};

/**
 * TODO: This should also accept the type created by formatEventForReactBigCalendar once that is
 * migrated to TypeScript.
 */
export function shouldShowTransparentEventBackgroundInMonthlyView({
  event,
  isInMonthlyView,
}: { event: TemporaryEvent, isInMonthlyView?: boolean }) {
  if (!isInMonthlyView) {
    return false;
  }
  if (shouldShowTransparentMergedBackground(event)) {
    // this is taken care of by `background` property
    return false;
  }
  // we only show background color on the event if it's an all day event or event that spans multiple days
  return (
    !event?.displayAsAllDay &&
    !doesEventSpanMultipleDays(event) &&
    !(isTemporaryAIEvent(event) || isFindTimeEventFormEvent(event))
  );
}

export function getTransparentModalStyle({ top, isDarkMode, right }): ModalStyles {
  return {
    overlay: {
      position: "fixed",
      inset: 0,
      backgroundColor: "transparent",
      zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
    },
    content: {
      padding: "0px",
      position: "absolute",
      top,
      right,
      left: "auto",
      bottom: "auto",
      borderRadius: 10,
      boxShadow: "0 0px 30px 3px rgba(0, 0, 0, 0.2)",
      border: getDefaultModalBorder(isDarkMode),
      zIndex: 5,
      backgroundColor: getModalBackgroundColorWithNoOpacity(isDarkMode),
      color: isDarkMode
        ? DARK_MODE_MODAL_TEXT_COLOR
        : "",
    },
  };
}

export function getDefaultModalStyle(): ModalStyles {
  return {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      zIndex: MODAL_OVERLAY_Z_INDEXES.LOW_PRIORITY,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderColor: "transparent",
      borderRadius: 5,
      boxShadow: "0 0px 30px 3px rgba(0, 0, 0, 0.2)",
      zIndex: 5,
    },
  };
}

export const LIGHT_MODE_AVAILABILITY_BORDER_COLOR = "rgba(215, 231, 252, 1)";
export function getAvailabilityPanelPreviewStyle({ isDarkMode }) {
  return {
    backgroundColor: isDarkMode ? "#343645" : "rgba(243, 247, 254, 1)",
    border: isDarkMode ? undefined : `1px solid ${LIGHT_MODE_AVAILABILITY_BORDER_COLOR}`,
  };
}

export const GOLD_STAR_HEX_CODE = "#C99E35";
