import React, { Component } from "react";
import { handleError } from "../services/commonUsefulFunctions";
import { triggerRefreshWithOnlineCheck } from "../services/appFunctions";
import VimcalIcon from "../focusMode/resources/vimcalIcon";
import { getStoredDarkMode } from "../lib/stateManagementFunctions";
import classNames from "classnames";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };

    this.refresh = this.refresh.bind(this);
  }

  static getDerivedStateFromError = (error) => {
    handleError(error);
    return { hasError: true };
  };

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
    handleError(err);
  }

  render() {
    return this.renderErrorWrapper();
  }

  renderErrorWrapper() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    const isDarkMode = getStoredDarkMode();
    return (
      <div className={classNames(isDarkMode ? "error-boundary-dark-mode" : "")}>
        <div className="flex items-center justify-center flex-col height-100vh w-screen default-font-size">
          <VimcalIcon
            className="opacity-50 mb-2"
            height={40}
            width={40}
            fillColor={"#91959C"}
          />
          <p className="secondary-text-color">
            An unexpected error occurred. Please retry
            <span className="loading-dot"></span>
            <span className="loading-dot"></span>
            <span className="loading-dot"></span>
          </p>
          <button className="retry-button mt-5" onClick={this.refresh}>
            Retry
          </button>
        </div>
      </div>
    );
  }

  refresh() {
    triggerRefreshWithOnlineCheck();
  }
}

export default ErrorBoundary;
