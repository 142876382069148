import { OUTLOOK_SETTINGS_LABEL } from "./conferencing";
import { GOOGLE_MEET } from "./conferencing";
import { PROVIDER_TYPES, isOutlookUser } from "./outlookFunctions";
import { getAccountState, isUserInFreeTrial } from "./stateManagementFunctions";
import {
  convertTrueFalseStringIntoValue,
  localData,
  isElectron,
} from "../services/commonUsefulFunctions";
import {
  BACKEND_4_DAY_VIEW,
  BACKEND_DAY_VIEW,
  BACKEND_MONTH,
  BACKEND_WEEK,
  EXCLUDED_DOMAINS,
  ROLLING_SEVEN_DAY,
} from "../services/globalVariables";
import {
  getAllPrimaryCalendarsIDsFromAllCalendars,
  getMatchingCalendarsForUser,
  getMatchingPrimaryCalendarForUser,
} from "./calendarFunctions";
import {
  getConnectedAccountUserName,
  isMaestroUserOnDelegatedAccount,
  isUserDelegatedUser,
  isUserMaestroUser,
} from "../services/maestroFunctions";
import {
  ACCOUNT_STATE_FREEMIUM,
  BACKEND_AVAILABILITY_SETTINGS_NAMES,
  BACKEND_SETTINGS_NAMES,
} from "./vimcalVariables";
import { isGoogleUser } from "../services/appFunctions";
import { tooltipKeys } from "../services/tooltipVariables";
import { LOCAL_DATA_ACTION } from "./localData";
import _ from "underscore";
import {
  AllCalendarsState,
  useAllLoggedInUsers,
} from "../services/stores/SharedAccountData";
import { getCalendarUserCalendarID } from "../services/calendarAccessors";
import {
  getAccountRawCalendarView,
  getDefaultUserTimeZone,
  getMenuBarCalendars,
} from "./settingsFunctions";
import {
  equalAfterTrimAndLowerCased,
  formatEmail,
  getDomainFromEmail,
  isSameEmail,
  lowerCaseAndTrimString,
  pluralize,
} from "./stringFunctions";
import {
  isEmptyArrayOrFalsey,
  isEmptyObjectOrFalsey,
} from "../services/typeGuards";
import { isLocal } from "../services/devFunctions";
import {
  getConnectedAccountProfilePicture,
  isUserFromMagicLink,
} from "../services/maestro/maestroAccessors";
import { getMasterAccountStripeEmail } from "../services/accountFunctions";

const USER_SEEN_REWIND = "USER_SEEN_REWIND";

type OptionalUser = User | null | undefined;
type OptionalUserArray = User[] | null | undefined;
type OptionalMasterAccount =
  | MasterAccount
  | Record<string, never>
  | null
  | undefined;

// Gets the default conferencing name for conference settings modal
// Outlook -> all options compressed to 'Outlook'
// Google -> Google Meets
export function getUserProviderConferencing(user: OptionalUser) {
  if (isOutlookUser(user)) {
    return OUTLOOK_SETTINGS_LABEL;
  } else {
    return GOOGLE_MEET;
  }
}

export function shouldShowTrialActive({
  masterAccount,
  defaultPaymentMethod,
}: {
  masterAccount: OptionalMasterAccount;
  defaultPaymentMethod:
    | DefaultPaymentMethod
    | Record<string, never>
    | null
    | undefined;
}) {
  return (
    isUserInFreeTrial(masterAccount) &&
    isEmptyObjectOrFalsey(defaultPaymentMethod)
  );
}

export function getDaysLeftHumanReadbleText(daysLeft: number) {
  return daysLeft <= 0
    ? "Last day"
    : `${daysLeft} ${pluralize(daysLeft, "day")} left`;
}

export function hasUserSeenRewindModal() {
  const hasSeen = localData(LOCAL_DATA_ACTION.GET, USER_SEEN_REWIND);
  if (!hasSeen) {
    return false;
  }

  return convertTrueFalseStringIntoValue(hasSeen);
}

export function setUserHasSeenRewindModal() {
  localData(LOCAL_DATA_ACTION.SET, USER_SEEN_REWIND, true);
}

export function getTimeZoneForMenuBar({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount;
}) {
  // if there's only one time zone, return that
  // otherwise, return guessTimeZone
  return getDefaultUserTimeZone({ masterAccount });
}

export function getUserDomain({ user }: { user: OptionalUser }) {
  const userEmail = getUserEmail(user);
  return getDomainFromEmail(userEmail);
}

const SHOULD_HIDE_REFERRAL_POPUP = "SHOULD_HIDE_REFERRAL_POPUP";
export function setShouldHideReferralPopup() {
  localData(LOCAL_DATA_ACTION.SET, SHOULD_HIDE_REFERRAL_POPUP, true);
}

export function getShouldHideReferralPopup() {
  return localData(LOCAL_DATA_ACTION.GET, SHOULD_HIDE_REFERRAL_POPUP);
}

export function getUpcomingCalendarUserCalendarIDsForUser({
  masterAccount,
  allCalendars,
  user,
}: {
  masterAccount: OptionalMasterAccount;
  allCalendars: AllCalendarsState["allCalendars"];
  user: OptionalUser;
}) {
  const filteredAllCalendars = getMatchingCalendarsForUser({
    allCalendars,
    user,
  });
  const upcomingCalendarUserCalendarIDs = getUpcomingCalendarUserCalendarIDs({
    masterAccount,
    allCalendars: filteredAllCalendars,
  });
  // upcomingCalendarUserCalendarIDs is pulled from master account settings. So now we have to filter the ones that are in allCalendars keys
  const filteredUserCalendarIDs = upcomingCalendarUserCalendarIDs.filter(
    (userCalendarID) =>
      Object.keys(filteredAllCalendars).includes(userCalendarID)
  );
  return filteredUserCalendarIDs;
}

export function getUpcomingCalendarUserCalendarIDs({
  masterAccount,
  allCalendars,
}: {
  masterAccount: OptionalMasterAccount;
  allCalendars: AllCalendarsState["allCalendars"];
}): string[] {
  // if addBackup and empty menubarcalendars, we add all the logged in accounts
  const ids = getMenuBarCalendars({ masterAccount }) ?? [];
  const hasMatchingCalendars = Object.keys(allCalendars ?? {}).some((item) =>
    ids.includes(item)
  );
  if (hasMatchingCalendars) {
    return ids;
  }

  if (!isEmptyObjectOrFalsey(allCalendars)) {
    if (isUserMaestroUser(masterAccount)) {
      // only get primary calendar for non-delegate calendars
      const allLoggedInUsers =
        useAllLoggedInUsers.getState()?.allLoggedInUsers ?? [];
      const nonDelegateUsers = getAllNonDelegateUsers(allLoggedInUsers);
      const nonDelegateUserCalendarIDs = nonDelegateUsers
        .map((user) =>
          getMatchingPrimaryCalendarForUser({ allCalendars, user })
        )
        .filter((calendar) => !!calendar)
        .map((calendar) => getCalendarUserCalendarID(calendar));
      return nonDelegateUserCalendarIDs;
    }
    return getAllPrimaryCalendarsIDsFromAllCalendars(allCalendars);
  }

  return [];
}

export function getMasterAccountSettings(
  masterAccount: OptionalMasterAccount | TruncatedMasterAccount
) {
  return masterAccount?.settings;
}

// used to track the user that the user selected for login
const ZOOM_LOGIN_USER_NAME = "zoom-login-user-email";
export function setZoomLoginUserName({
  userEmail,
}: {
  userEmail: string | null | undefined;
}) {
  if (!userEmail) {
    return;
  }
  localData(LOCAL_DATA_ACTION.SET, ZOOM_LOGIN_USER_NAME, userEmail);
}

export function getZoomLoginUserName(allLoggedInUsers: OptionalUserArray) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return null;
  }

  const lastUserEmail = localData(LOCAL_DATA_ACTION.GET, ZOOM_LOGIN_USER_NAME);
  if (!lastUserEmail) {
    return null;
  }

  return allLoggedInUsers.find((user) =>
    isSameEmail(getUserEmail(user), lastUserEmail)
  );
}

export function getAccountUpdatedAt({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount;
}) {
  return masterAccount?.updated_at;
}

export function updateUserSettings({
  user,
  updatedSettings,
}: {
  user: User;
  updatedSettings: Partial<UserSettings>;
}): User;
export function updateUserSettings({
  user,
  updatedSettings,
}: {
  user: null | undefined;
  updatedSettings: Partial<UserSettings>;
}): Record<string, never>;
export function updateUserSettings({
  user,
  updatedSettings,
}: {
  user: OptionalUser;
  updatedSettings: Partial<UserSettings>;
}) {
  if (isEmptyObjectOrFalsey(user) || isEmptyObjectOrFalsey(updatedSettings)) {
    return user ?? {};
  }

  const { settings } = user;

  const newSettings: UserSettings = { ...settings, ...updatedSettings };
  return { ...user, settings: newSettings };
}

export function updateUserSmartTags({
  user,
  smartTags,
}: {
  user: OptionalUser;
  smartTags: SmartTag[] | null | undefined;
}) {
  if (isEmptyObjectOrFalsey(user) || !smartTags) {
    return user ?? {};
  }

  return { ...user, smart_tags: smartTags };
}

export function updateMasterAccountSettings<
  T extends MasterAccount | Record<string, never> | null | undefined
>({
  masterAccount,
  updatedSettings,
}: {
  masterAccount: T;
  updatedSettings: Partial<MasterAccountSettings>;
}): T {
  if (
    isEmptyObjectOrFalsey(masterAccount) ||
    isEmptyObjectOrFalsey(updatedSettings)
  ) {
    return masterAccount;
  }

  const newSettings: MasterAccountSettings = {
    ...masterAccount.settings,
    ...updatedSettings,
  };
  return { ...masterAccount, settings: newSettings };
}

// this is for viewing and turns the string into a int that rbc can ingest
export function getAccountCalendarView({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount;
}) {
  const calendarView = getAccountRawCalendarView({ masterAccount });
  switch (calendarView) {
    case 1:
      return 1;
    case 4 || "4":
      return 4;
    case BACKEND_4_DAY_VIEW:
      return 4;
    case BACKEND_DAY_VIEW:
      return 1;
    case BACKEND_WEEK:
      return 7;
    case 7 || "7":
      return 7;
    case BACKEND_MONTH:
      return BACKEND_MONTH;
    case ROLLING_SEVEN_DAY:
      return ROLLING_SEVEN_DAY;
    default:
      return 7; // default to week view
  }
}

export function getAccountUserName({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount;
}) {
  return getMasterAccountSettings(masterAccount)?.username || "";
}

export function getCurrentPainterColors({ user }: { user: OptionalUser }) {
  return user?.[BACKEND_SETTINGS_NAMES.SMART_TAGS] ?? [];
}

export function getSelectedUserName({ user }: { user: OptionalUser }) {
  const firstName = user?.first_name ?? "";
  const lastName = user?.last_name ?? "";
  // User user.full_name else check if we have first and last
  // If we do, then use `${firstName} ${lastName}` instead
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : user?.full_name ?? "";

  return {
    firstName: firstName,
    lastName: lastName,
    fullName: fullName,
    userName: user?.settings?.username ?? "",
  };
}

export function getAccountName({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount | TruncatedMasterAccount;
}) {
  return {
    firstName: masterAccount?.first_name ?? "",
    lastName: masterAccount?.last_name ?? "",
    fullName: getAccountFullName({ masterAccount }),
    userName: getMasterAccountSettings(masterAccount)?.username ?? "",
  };
}

function getAccountFullName({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount | TruncatedMasterAccount;
}) {
  if (isEmptyObjectOrFalsey(masterAccount)) {
    return "";
  }

  if (masterAccount?.first_name && masterAccount?.last_name) {
    return `${masterAccount.first_name} ${masterAccount.last_name}`;
  }

  if ("full_name" in masterAccount) {
    return masterAccount?.full_name || "";
  }

  return "";
}

export function getUserName({
  masterAccount,
  user,
}: {
  masterAccount: OptionalMasterAccount;
  user: OptionalUser;
}) {
  /* Magic link users store username on user level */
  if (isUserFromMagicLink({ user })) {
    return getConnectedAccountUserName({ user });
  }

  if (isMaestroUserOnDelegatedAccount({ masterAccount, user })) {
    return getSelectedUserName({ user });
  }

  const userNameObject = getSelectedUserName({ user });
  const userNameFullName = userNameObject?.fullName;
  if (userNameFullName?.trim()) {
    return {
      ...getAccountName({ masterAccount }),
      fullName: userNameFullName,
    };
  }

  return getAccountName({ masterAccount });
}

export function getProfilePhotoUrl({
  masterAccount,
  user,
}: {
  masterAccount: OptionalMasterAccount;
  user: OptionalUser;
}) {
  /* For magic link profile photo, we need to use the one on the magic link user's master account */
  if (isUserFromMagicLink({ user })) {
    return getConnectedAccountProfilePicture({ user });
  }

  if (isMaestroUserOnDelegatedAccount({ masterAccount, user })) {
    return user?.internal_profile_photo_url;
  } else {
    return masterAccount?.internal_profile_photo_url;
  }
}

export function getUserProfilePhotoUrl({ user }: { user: OptionalUser }) {
  return user?.profile_photo_url;
}

export function getSocialLinks({
  masterAccount,
  user,
}: {
  masterAccount: OptionalMasterAccount;
  user: OptionalUser;
}) {
  if (
    isMaestroUserOnDelegatedAccount({ masterAccount, user }) ||
    isUserFromMagicLink({ user })
  ) {
    return user?.social_links;
  } else {
    return masterAccount?.social_links;
  }
}

export function getMasterAccountEmail({
  masterAccount,
}: {
  masterAccount: OptionalMasterAccount;
}) {
  return formatEmail(masterAccount?.stripe_email);
}

export function getSlotsSettingsShowAllTimeZones({
  currentUser,
  masterAccount,
}: {
  currentUser: OptionalUser;
  masterAccount: OptionalMasterAccount;
}) {
  return (
    currentUser?.availability_settings?.[
      BACKEND_AVAILABILITY_SETTINGS_NAMES.SLOTS_SHOW_ALL_TIMEZONES
    ] ?? isUserMaestroUser(masterAccount)
  );
}

export function getMatchingUserFromAllUsers({
  allUsers,
  userEmail,
}: {
  allUsers: OptionalUserArray;
  userEmail: string | null | undefined;
}) {
  if (!userEmail || isEmptyArrayOrFalsey(allUsers)) {
    return null;
  }
  return allUsers.find((user) => isSameEmail(getUserEmail(user), userEmail));
}

export function getMatchingNonDelegatedUserFromAllUsers({
  allUsers,
  userEmail,
}: {
  allUsers: OptionalUserArray;
  userEmail: string | null | undefined;
}) {
  if (!userEmail || isEmptyArrayOrFalsey(allUsers)) {
    return null;
  }
  return allUsers.find(
    (user) =>
      isSameEmail(getUserEmail(user), userEmail) && !isUserDelegatedUser(user)
  );
}

export function getMatchingExecUserFromAllUsers({
  allUsers,
  userEmail,
}: {
  allUsers: OptionalUserArray;
  userEmail: string | null | undefined;
}) {
  if (!userEmail || isEmptyArrayOrFalsey(allUsers)) {
    return null;
  }
  return allUsers.find(
    (user) =>
      isSameEmail(getUserEmail(user), userEmail) && isUserDelegatedUser(user)
  );
}

export function getPrimaryUser({
  masterAccount,
  allLoggedInUsers,
}: {
  masterAccount: OptionalMasterAccount;
  allLoggedInUsers: OptionalUserArray;
}) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return null;
  }
  const primaryUser = allLoggedInUsers.find(
    (user) => user?.email === masterAccount?.stripe_email
  );
  return primaryUser;
}

export function getMasterAccountCreatedAt(
  masterAccount: OptionalMasterAccount
) {
  return masterAccount?.created_at;
}

export function getAccountHasSelfServeAccess(
  masterAccount: OptionalMasterAccount
) {
  return true;
  if (getAccountState(masterAccount) === ACCOUNT_STATE_FREEMIUM) {
    return true;
  }

  if (isElectron()) {
    return true;
  }

  return convertTrueFalseStringIntoValue(
    masterAccount?.has_access_to_self_serve
  );
}

export function getMasterAccountID(masterAccount: MasterAccount): number;
export function getMasterAccountID(
  masterAccount: null | undefined | Record<string, never>
): undefined;
export function getMasterAccountID(masterAccount: OptionalMasterAccount) {
  return masterAccount?.id;
}

export function getUserProvider(user: OptionalUser) {
  const provider = user?.provider;
  return provider === PROVIDER_TYPES.OUTLOOK
    ? PROVIDER_TYPES.OUTLOOK
    : PROVIDER_TYPES.GOOGLE;
}

export function getUserSlotsCustomQuestions(user: OptionalUser) {
  return user?.availability_settings?.custom_questions;
}

export function getOutlookAndGoogleUsers({
  allLoggedInUsers,
  currentUser,
}: {
  allLoggedInUsers: OptionalUserArray;
  currentUser: OptionalUser;
}) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return { googleUsers: [], outlookUsers: [] };
  }
  const googleUsers = sortFetchUsers({
    users: allLoggedInUsers.filter((u) => isGoogleUser(u)),
    currentUser,
  });
  const outlookUsers = sortFetchUsers({
    users: allLoggedInUsers.filter((u) => isOutlookUser(u)),
    currentUser,
  });
  return {
    googleUsers,
    outlookUsers,
  };
}

export function getAccountCompletedToolTips(
  masterAccount: OptionalMasterAccount
) {
  return masterAccount?.completed_tooltips ?? [];
}

export function hasCompletedAllTutorialWizard(
  masterAccount: OptionalMasterAccount
) {
  const completedToolTips = getAccountCompletedToolTips(masterAccount);
  return (
    completedToolTips.includes(tooltipKeys.SLOTS) &&
    completedToolTips.includes(tooltipKeys.NLP) &&
    completedToolTips.includes(tooltipKeys.TIME_TRAVEL)
  );
}

// always put currentUser first
export function sortFetchUsers({
  users,
  currentUser,
}: {
  users: OptionalUserArray;
  currentUser: OptionalUser;
}) {
  if (isEmptyArrayOrFalsey(users)) {
    return [];
  }
  if (!currentUser) {
    return users;
  }
  let sortedUsers: User[] = [];
  users.forEach((user) => {
    if (equalAfterTrimAndLowerCased(user?.email, currentUser?.email)) {
      sortedUsers = [user].concat(sortedUsers);
    } else {
      sortedUsers = sortedUsers.concat(user);
    }
  });
  return sortedUsers;
}

export function getUserToken(user: User | TruncatedUser): string;
export function getUserToken(user: null | undefined): undefined;
export function getUserToken(
  user: User | TruncatedUser | null | undefined
): string | undefined;
export function getUserToken(
  user: User | TruncatedUser | null | undefined
): string | undefined {
  try {
    return user?.token;
  } catch (e) {
    return;
  }
}

export function getUserEmail(user: User | TruncatedUser): string;
export function getUserEmail(user: null | undefined): null;
export function getUserEmail(user: OptionalUser | TruncatedUser): string | null;
export function getUserEmail(
  user: OptionalUser | TruncatedUser
): string | null {
  try {
    return lowerCaseAndTrimString(user?.email);
  } catch (e) {
    return null;
  }
}

export function isUserHideMetricsNotifications(
  masterAccount: OptionalMasterAccount
) {
  return masterAccount?.receive_metrics_notifications === false;
}

export function getUserAccountUpdatedAtTime({
  currentUser,
  masterAccount,
}: {
  currentUser: OptionalUser;
  masterAccount: OptionalMasterAccount;
}) {
  if (isMaestroUserOnDelegatedAccount({ masterAccount, user: currentUser })) {
    return getUserSettingsUpdatedAt(currentUser);
  }
  return getMasterAccountSettingsUpdatedAt(masterAccount);
}

function getMasterAccountSettingsUpdatedAt(
  masterAccount: OptionalMasterAccount
) {
  return getMasterAccountSettings(masterAccount)?.updated_at;
}

function getUserSettingsUpdatedAt(user: OptionalUser) {
  return user?.settings?.updated_at;
}

// The settings fields is actually a representation of the Settings record associated with this Master Account.
// As such it has it’s own created_at and updated_at values
export function shouldUpdateLocalMasterAccountSettings({
  currMasterAccount,
  newMasterAccount,
}: {
  currMasterAccount: OptionalMasterAccount;
  newMasterAccount: OptionalMasterAccount;
}) {
  return !_.isEqual(currMasterAccount, newMasterAccount);
}

export function shouldUpdateMasterAccount({
  currMasterAccount,
  newMasterAccount,
}: {
  currMasterAccount: OptionalMasterAccount;
  newMasterAccount: OptionalMasterAccount;
}) {
  // Returning true when the objects are identical may cause additional renders.
  return !_.isEqual(currMasterAccount, newMasterAccount);
}

export function doesAllLoggedInUsersContainOutlookUsers(
  allLoggedInUsers: OptionalUserArray
) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return false;
  }
  return allLoggedInUsers.some((user) => isOutlookUser(user));
}

export function getAllNonDelegateUsers(allLoggedInUsers: OptionalUserArray) {
  if (isEmptyObjectOrFalsey(allLoggedInUsers)) {
    return [];
  }

  return allLoggedInUsers.filter((user) => !isUserDelegatedUser(user));
}

export function isSameUser(user1: User, user2: User) {
  const user1Email = getUserEmail(user1);
  const user2Email = getUserEmail(user2);
  if (!user1Email || !user2Email) {
    return false;
  }
  return isSameEmail(user1Email, user2Email);
}

export function doesAllLoggedInUsersContainGoogleUsers(
  allLoggedInUsers: OptionalUserArray
) {
  if (isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return false;
  }
  return allLoggedInUsers.some((user) => isGoogleUser(user));
}

export function getUserPersonalZoomLink(user: OptionalUser) {
  return user?.zoom_link;
}

export function isUserSSOAdmin(masterAccount: OptionalMasterAccount) {
  if (isLocal()) {
    return true;
  }
  return masterAccount?.is_sso_admin || false;
}

export function getSAMLAccountID(masterAccount: OptionalMasterAccount) {
  return masterAccount?.saml_account_id;
}

export function isUserSSOUser(masterAccount: OptionalMasterAccount) {
  return !!getSAMLAccountID(masterAccount);
}

export function getAccountReferralCode(masterAccount: OptionalMasterAccount) {
  return masterAccount?.referral_code;
}

export function getAuthenticatedUserFromUser(user) {
  try {
    return user?.authenticated_user;
  } catch (e) {
    return null;
  }
}

export function isUserEmailPartOfAllLoggedInUsers({
  allLoggedInUsers,
  userEmail,
}: {
  allLoggedInUsers: OptionalUserArray;
  userEmail: string | null | undefined;
}) {
  if (!userEmail || isEmptyArrayOrFalsey(allLoggedInUsers)) {
    return false;
  }
  return allLoggedInUsers.some((user) =>
    isSameEmail(getUserEmail(user), userEmail)
  );
}

export function getBestGuessOutlookNonDelegatedUser({
  masterAccount,
  currentUser,
  allLoggedInUsers,
}) {
  // only outlook users should get into here.
  if (!isUserDelegatedUser(currentUser) && isOutlookUser(currentUser)) {
    return currentUser;
  }

  const authenticatedUser = getAuthenticatedUserFromUser(currentUser);
  if (authenticatedUser && isOutlookUser(authenticatedUser)) {
    return authenticatedUser;
  }

  // find first outlook user that is not a delegated user and has the same email as the master account stripe email
  const userMatchingMasterAccountEmail = allLoggedInUsers.find(
    (user) =>
      !isUserDelegatedUser(user) &&
      isOutlookUser(user) &&
      isSameEmail(
        getUserEmail(user),
        getMasterAccountStripeEmail(masterAccount)
      )
  );

  if (userMatchingMasterAccountEmail) {
    return userMatchingMasterAccountEmail;
  }

  // if google user -> get first outlook user
  const firstOutlookUser = allLoggedInUsers.find(
    (user) => !isUserDelegatedUser(user) && isOutlookUser(user)
  );
  if (isGoogleUser(currentUser) && firstOutlookUser) {
    return firstOutlookUser;
  }

  // default to current user email
  return currentUser;
}
